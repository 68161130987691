import Flex from "components/shared-components/Flex";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LogoPLN from "assets/image/logo-pln.png";
import LogoSMP from "assets/image/logo-smp.png";
import services from "services/guests";
import moment from "moment";
import "moment/locale/id";
import { useLocation } from "react-router-dom";
import { Col, Row, Spin } from "antd";

moment.locale("id");

const BLUE_BACKGROUND = "#D2EBFD";

const ContainerReportInspection = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  padding: 20px 15px;
`;

const WrapperReport = styled.div`
  background-color: white;
  border: solid 1px black;
  width: 100%;
`;

const WrapperHeaderReport = styled.div`
  background-color: ${BLUE_BACKGROUND};
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  color: black;

  td {
    font-weight: bold !important;
  }
`;

const WrapperLeadReport = styled.div`
  background-color: white;
  width: 100%;
  font-size: 11px;
  color: black;
  letter-spacing: 0.3px;
`;

const WrapperBodyReport = styled.div`
  background-color: white;
  width: 100%;
  font-size: 11px;
  color: black;
  letter-spacing: 0.3px;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: ${({ height }) => (height ? height : "26px")};
  width: 100%;
  /* border-right: solid 1px black; */
`;

const TableCol = styled.td`
  border: ${({ noBorder }) => (noBorder ? 0 : "solid 1px black")};
  border-top: ${({ noBorderTop }) => (noBorderTop ? 0 : "solid 1px black")};
  border-bottom: 0;
  border-right: 0;
  border-left: ${({ noBorderLeft }) => (noBorderLeft ? 0 : "solid 1px black")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  width: ${({ width }) => (width ? width : null)};
  flex: ${({ width }) => (width ? null : 1)};
  padding: 5px;
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  ${({ leftMiddle }) =>
    leftMiddle &&
    `display: flex; justify-content: flex-start; align-items: center;`};
  ${({ middle }) =>
    middle && `display: flex; justify-content: center; align-items: center;`};
`;

const Logo = styled.img`
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: contain;
`;

const defaultDetail = {
  id: 0,
  id_substation: "",
  name: "",
  description: "",
  appointment: 0,
  plates: "",
  meet: "",
  institution: "",
  clock_in: "",
  clock_out: "",
  created_at: "",
  updated_at: "",
  Substation: {
    name: "",
  },
};

const ReportGuest = () => {
  const [data, setData] = useState(defaultDetail);
  const [dataOfficial, setDataOfficial] = useState({
    security: { fullname: "" },
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const idGuest = queryParams.get("guest");
  const [isLoading, setLoading] = useState(true);

  const printDocument = () => window.print();

  const handleFetchReport = async () => {
    try {
      setLoading(true);
      const response = await services.getGuestDetail(idGuest);
      setData(response.data);
      setDataOfficial(response?.official);
      setLoading(false);

      const css = "body {background-color: white;}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      head.appendChild(style);

      printDocument();
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    handleFetchReport();
  }, []);

  if (isLoading) {
    return (
      <Row className="m-5">
        <Col lg={24} className="text-center">
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  return (
    <ContainerReportInspection>
      <WrapperReport>
        <WrapperHeaderReport>
          <TableRow height="80px">
            <TableCol noBorderLeft noBorderTop leftMiddle width="35%">
              <Logo src={LogoPLN} />
              PT PLN (PERSERO) UIP3B SUMATERA
            </TableCol>
            <TableCol textAlign="center" noBorderTop middle width="30%">
              FORMULIR IJIN BERTAMU
            </TableCol>
            <TableCol middle noBorderTop width="35%">
              <Flex alignItems="center">
                <Logo src={LogoSMP} />
                SISTEM MANAJEMEN PENGAMANAN
              </Flex>
            </TableCol>
          </TableRow>
          <TableRow height="40px">
            <TableCol noBorderLeft width="35%">
              No. Dokumen
            </TableCol>
            <TableCol width="20%">FM-SMP-UIP3BS-UPT-UP2B-027</TableCol>
            <TableCol width="10%">Tanggal</TableCol>
            <TableCol width="35%">
              {moment().format("DD MMMM YYYY").toUpperCase()}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderLeft width="35%">
              Revisi
            </TableCol>
            <TableCol width="20%"></TableCol>
            <TableCol width="10%">Halaman</TableCol>
            <TableCol width="35%"></TableCol>
          </TableRow>
        </WrapperHeaderReport>

        <WrapperLeadReport>
          <TableRow height="40px">
            <TableCol noBorderLeft width="100%" noBorderRight>
              Hari/Tanggal :{" "}
              {moment(data?.clock_in).format("dddd, DD MMMM YYYY")}
            </TableCol>
          </TableRow>
        </WrapperLeadReport>
        <WrapperBodyReport>
          <TableRow height="30px">
            <TableCol
              noBorderLeft
              fontWeight="bold"
              textAlign="center"
              middle
              width="50%"
            >
              TAMU
            </TableCol>
            <TableCol fontWeight="bold" textAlign="center" middle width="50%">
              IJIN BERTAMU
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderLeft width="15%">
              Nama
            </TableCol>
            <TableCol width="35%">{data?.name}</TableCol>
            <TableCol width="15%">Nama</TableCol>
            <TableCol width="35%">{data?.meet}</TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderLeft width="15%">
              Perusahaan
            </TableCol>
            <TableCol width="35%">{data?.institution}</TableCol>
            <TableCol width="15%">Jabatan</TableCol>
            <TableCol width="35%">-</TableCol>
          </TableRow>
          <TableRow height="45px">
            <TableCol noBorderLeft width="15%">
              Alamat
            </TableCol>
            <TableCol width="35%">-</TableCol>
            <TableCol width="15%">Keperluan</TableCol>
            <TableCol width="35%">{data?.description}</TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderLeft width="15%">
              No. Kendaaraan
            </TableCol>
            <TableCol width="35%">{data?.plates ?? "-"}</TableCol>
            <TableCol width="15%">Dengan Janji</TableCol>
            <TableCol width="35%">
              {data?.appointment ? "Iya" : "Tidak"}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderLeft width="15%">
              Jam Masuk
            </TableCol>
            <TableCol width="35%">
              {moment(data?.clock_in).format("dddd, DD MMMM YYYY HH:mm [WIB]")}
            </TableCol>
            <TableCol width="15%">Jam Keluar</TableCol>
            <TableCol width="35%">
              {data?.clock_out
                ? moment(data?.clock_out).format(
                    "dddd, DD MMMM YYYY HH:mm [WIB]"
                  )
                : "-"}
            </TableCol>
          </TableRow>
          <TableRow height="30px">
            <TableCol
              noBorderLeft
              fontWeight="bold"
              textAlign="center"
              middle
              width="100%"
            >
              TANDA TANGAN
            </TableCol>
          </TableRow>
          <TableRow height="30px">
            <TableCol
              noBorderLeft
              fontWeight="bold"
              textAlign="center"
              middle
              width={`${100 / 3}%`}
            >
              PETUGAS SATPAM
            </TableCol>
            <TableCol
              fontWeight="bold"
              textAlign="center"
              middle
              width={`${100 / 3}%`}
            >
              TAMU
            </TableCol>
            <TableCol
              fontWeight="bold"
              textAlign="center"
              middle
              width={`${100 / 3}%`}
            >
              PEGAWAI YANG DITEMUI
            </TableCol>
          </TableRow>
          <TableRow height="90px">
            <TableCol
              noBorderLeft
              fontWeight="bold"
              textAlign="center"
              width={`${100 / 3}%`}
            ></TableCol>
            <TableCol
              fontWeight="bold"
              textAlign="center"
              width={`${100 / 3}%`}
            ></TableCol>
            <TableCol
              fontWeight="bold"
              textAlign="center"
              width={`${100 / 3}%`}
            ></TableCol>
          </TableRow>
          <TableRow>
            <TableCol
              noBorderTop
              noBorderLeft
              fontWeight="bold"
              textAlign="center"
              width={`${100 / 3}%`}
            >
              {(dataOfficial?.security?.fullname).toUpperCase()}
            </TableCol>
            <TableCol
              noBorderTop
              fontWeight="bold"
              textAlign="center"
              width={`${100 / 3}%`}
            >
              {(data?.name).toUpperCase()}
            </TableCol>
            <TableCol
              noBorderTop
              fontWeight="bold"
              textAlign="center"
              width={`${100 / 3}%`}
            >
              {(data?.meet).toUpperCase()}
            </TableCol>
          </TableRow>
        </WrapperBodyReport>
      </WrapperReport>
    </ContainerReportInspection>
  );
};

export default ReportGuest;
