import fetch from "helper/FetchInterceptor";

const services = {};

services.login = function (data) {
  return fetch({
    url: "/auth/login",
    method: "post",
    data,
  });
};

services.logout = function (data) {
  return fetch({
    url: "/auth/logout",
    method: "post",
    data,
  });
};

export default services;
