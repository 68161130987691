import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { AUTH_TOKEN, REFRESH_TOKEN, SIGNIN, SIGNOUT } from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
} from "../actions/Auth";

import { authServices } from "services";
import { PROFILE } from "redux/constants/Profile";

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      const data = yield call(authServices.login, payload);
      if (data.status === "success") {
        localStorage.setItem(AUTH_TOKEN, data.data.token);
        localStorage.setItem(REFRESH_TOKEN, data.data.refresh_token);
        localStorage.setItem(PROFILE, JSON.stringify(data.data));
        yield put(authenticated(data.data.token, data.data.refresh_token));
        window.location.href = "/";
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* ({ payload }) {
    try {
      const data = yield call(authServices.logout, payload);
      if (data.status === "success") {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(PROFILE);
        yield put(signOutSuccess(data));
      } else {
        yield put(showAuthMessage(data.message));
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(PROFILE);
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithEmail), fork(signOut)]);
}
