import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect, useSelector } from "react-redux";
import {
  SettingOutlined,
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { Link } from "react-router-dom";

const menuItem = [
  // {
  //   title: "Pengaturan",
  //   icon: SettingOutlined,
  //   path: "/app/settings/edit-profile",
  // },
  // {
  //   title: "Ganti Password",
  //   icon: LockOutlined,
  //   path: "/app/settings/change-password",
  // },
];

export const NavProfile = ({ signOut, data }) => {
  const { fullname, role } = data;
  const { profile } = useSelector((state) => state);
  const profileImg = "/img/avatars/people.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{fullname}</h4>
            <span className="text-muted">{role && "Super Admin"}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => signOut({ user_id: profile.id })}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Keluar</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

NavProfile.defaultProps = {
  data: {
    fullname: "Super Admin",
    role: "Super Admin",
  },
};

export default connect(null, { signOut })(NavProfile);
