import {
  FETCH_INSPECTION,
  FETCH_INSPECTION_ERROR,
  FETCH_INSPECTION_SUCCESS,
} from "redux/constants/Inspection";

export const fetchInspection = (params) => {
  return {
    type: FETCH_INSPECTION,
    params,
  };
};

export const fetchInspectionSuccess = (data, pagination) => {
  return {
    type: FETCH_INSPECTION_SUCCESS,
    data,
    pagination,
  };
};

export const fetchInspectionError = (error) => {
  return {
    type: FETCH_INSPECTION_ERROR,
    error,
  };
};
