import fetch from "helper/FetchInterceptor";

const services = {};

services.getPointInspection = function (params) {
  return fetch({
    url: "/point-inspection",
    method: "get",
    params,
  });
};

services.addPointInspection = function (data) {
  return fetch({
    url: "/point-inspection",
    method: "post",
    data,
  });
};

services.updatePointInspection = function (id, data) {
  return fetch({
    url: `/point-inspection/${id}`,
    method: "put",
    data,
  });
};

services.deletePointInspection = function (id) {
  return fetch({
    url: `/point-inspection/${id}`,
    method: "delete",
  });
};

export default services;
