import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  fetchUserSuccess,
  fetchUserError,
  registerUserSuccess,
  registerUserError,
} from "../actions/User";

import { userServices } from "services";
import { FETCH_USER, REGISTER_USER } from "redux/constants/User";
import { notification } from "antd";

export function* getUserList() {
  yield takeEvery(FETCH_USER, function* ({ params }) {
    try {
      const data = yield call(userServices.getUsers, params);
      if (data.status === "success") {
        const pagination = {
          page: params.page,
          limit: params.limit,
          total: data.data.total,
        };
        yield put(fetchUserSuccess(data.data.users, pagination));
      }
    } catch (err) {
      if (err.response.message) {
        yield put(fetchUserError(err.response.message));
      } else {
        yield put(fetchUserError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export function* registerUser() {
  yield takeEvery(REGISTER_USER, function* ({ data }) {
    try {
      const response = yield call(userServices.addUsers, data);
      if (response.status === "success") {
        yield put(registerUserSuccess());
        notification.success("Berhasil Mendaftarkan Pengguna");
      }
    } catch (err) {
      console.log(err);
      if (err.response.message) {
        yield put(registerUserError(err.response.message));
      } else {
        yield put(registerUserError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getUserList), fork(registerUser)]);
}
