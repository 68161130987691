import { combineReducers } from "redux";
import Auth from "./Auth";
import guest from "./Guest";
import Profile from "./Profile";
import Substation from "./Substation";
import Attendance from "./Attendance";
import Inspection from "./Inspection";
import PointInspection from "./PointInspection";
import Theme from "./Theme";
import User from "./User";
import Stats from "./Stats";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  profile: Profile,
  guest: guest,
  user: User,
  substation: Substation,
  attendance: Attendance,
  inspection: Inspection,
  pointInspection: PointInspection,
  stats: Stats,
});

export default reducers;
