import {
  FETCH_ATTENDANCE,
  FETCH_ATTENDANCE_ERROR,
  FETCH_ATTENDANCE_SUCCESS,
} from "redux/constants/Attendance";

export const fetchAttendance = (params) => {
  return {
    type: FETCH_ATTENDANCE,
    params,
  };
};

export const fetchAttendanceSuccess = (data, pagination) => {
  return {
    type: FETCH_ATTENDANCE_SUCCESS,
    data,
    pagination,
  };
};

export const fetchAttendanceError = (error) => {
  return {
    type: FETCH_ATTENDANCE_ERROR,
    error,
  };
};
