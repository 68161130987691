import {
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
  FileProtectOutlined,
  TeamOutlined,
  SecurityScanOutlined,
  SolutionOutlined,
  PartitionOutlined,
  AimOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.app",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "sidenav.app.dashboard",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "guest",
        path: `${APP_PREFIX_PATH}/guest`,
        title: "sidenav.app.guest",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "attendance",
        path: `${APP_PREFIX_PATH}/attendance`,
        title: "sidenav.app.attendance",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "inspection",
        path: `${APP_PREFIX_PATH}/inspection`,
        title: "sidenav.app.inspection",
        icon: SecurityScanOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "point-inspection",
        path: `${APP_PREFIX_PATH}/point-inspection`,
        title: "sidenav.app.point-inspection",
        icon: AimOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report",
        path: `${APP_PREFIX_PATH}/report`,
        title: "sidenav.app.report",
        icon: FileProtectOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "report-guest",
            path: `${APP_PREFIX_PATH}/report/guest`,
            title: "sidenav.app.report.guest",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "report-inspection",
            path: `${APP_PREFIX_PATH}/report/inspection`,
            title: "sidenav.app.report.inspection",
            icon: SecurityScanOutlined,
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "report-attendance",
          //   path: `${APP_PREFIX_PATH}/report/attendance`,
          //   title: "sidenav.app.report.attendance",
          //   icon: SolutionOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
      {
        key: "substation",
        path: `${APP_PREFIX_PATH}/substation`,
        title: "sidenav.app.substation",
        icon: PartitionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/users`,
        title: "sidenav.app.users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "settings",
      //   path: `${APP_PREFIX_PATH}/settings`,
      //   title: "sidenav.app.settings",
      //   icon: SettingOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
