import {
  FETCH_SUBSTATION,
  FETCH_SUBSTATION_ERROR,
  FETCH_SUBSTATION_SUCCESS,
} from "redux/constants/Substation";

export const fetchSubstation = (params) => {
  return {
    type: FETCH_SUBSTATION,
    params,
  };
};

export const fetchSubstationSuccess = (data, pagination) => {
  return {
    type: FETCH_SUBSTATION_SUCCESS,
    data,
    pagination,
  };
};

export const fetchSubstationError = (error) => {
  return {
    type: FETCH_SUBSTATION_ERROR,
    error,
  };
};
