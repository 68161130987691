import fetch from "helper/FetchInterceptor";

const services = {};

services.getStats = function () {
  return fetch({
    url: "/stats",
    method: "get",
  });
};

export default services;
