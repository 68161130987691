import fetch from "helper/FetchInterceptor";

const services = {};

services.getGuest = function (params) {
  return fetch({
    url: "/guest",
    method: "get",
    params,
  });
};

services.getGuestDetail = function (id) {
  return fetch({
    url: `/guest/detail/${id}`,
    method: "get",
  });
};

export default services;
