import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  fetchSubstationSuccess,
  fetchSubstationError,
} from "../actions/Substation";

import { substationService } from "services";
import { FETCH_SUBSTATION } from "redux/constants/Substation";

export function* getSubstationList() {
  yield takeEvery(FETCH_SUBSTATION, function* ({ params }) {
    try {
      const data = yield call(substationService.getSubstation, params);
      if (data.status === "success") {
        const pagination = {
          page: params.page,
          limit: params.limit,
          total: data.data.total,
        };
        yield put(fetchSubstationSuccess(data.data.substations, pagination));
      }
    } catch (err) {
      if (err.response.message) {
        yield put(fetchSubstationError(err.response.message));
      } else {
        yield put(fetchSubstationError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getSubstationList)]);
}
