import Flex from "components/shared-components/Flex";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import LogoPLN from "assets/image/logo-pln.png";
import LogoSMP from "assets/image/logo-smp.png";
import { useLocation } from "react-router-dom";
import { Col, notification, Row, Spin } from "antd";
import ServicesGuest from "services/report";
import ServicesSub from "services/substation";
import moment from "moment";
import "moment/locale/id";

moment.locale("id");

const BLUE_BACKGROUND = "#D2EBFD";

const ContainerReportInspection = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  padding: 20px 15px;
`;

const WrapperReport = styled.div`
  background-color: white;
  /* border: solid 1px black; */
  width: 100%;
`;

const WrapperHeaderReport = styled.div`
  background-color: ${BLUE_BACKGROUND};
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  color: black;
`;

const WrapperLeadReport = styled.div`
  background-color: white;
  width: 100%;
  font-size: 11px;
  color: black;
  letter-spacing: 0.3px;
`;

const WrapperBodyReport = styled.div`
  background-color: white;
  width: 100%;
  font-size: 11px;
  color: black;
  letter-spacing: 0.3px;
  border-bottom: solid 1px black;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: ${({ height }) => (height ? height : "26px")};
  width: 100%;
  border-right: solid 1px black;
`;

const TableCol = styled.td`
  border: ${({ noBorder }) => (noBorder ? 0 : "solid 1px black")};
  border-top: ${({ noBorderTop }) => (noBorderTop ? 0 : "solid 1px black")};
  border-bottom: 0;
  border-right: 0;
  border-left: ${({ noBorderLeft }) => (noBorderLeft ? 0 : "solid 1px black")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  width: ${({ width }) => (width ? width : null)};
  flex: ${({ width }) => (width ? null : 1)};
  padding: 5px;
  ${({ leftMiddle }) =>
    leftMiddle &&
    `display: flex; justify-content: flex-start; align-items: center;`};
  ${({ middle }) =>
    middle && `display: flex; justify-content: center; align-items: center;`};
`;

const Logo = styled.img`
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: contain;
`;

var optionsTime = { hour: "2-digit", minute: "2-digit" };

const PrintGuest = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("start_date");
  const endDate = queryParams.get("end_date");
  const substation = queryParams.get("substation");

  const [list, setList] = useState([]);
  const [dataSubstation, setDataSubstation] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [dataReport, setdataReport] = useState();

  const printDocument = () => window.print();

  const convertPeriode = useCallback((date) => {
    return moment(date).format("MMMM YYYY");
  }, []);

  async function fetchReport(params) {
    try {
      setLoading(true);
      const dataGuest = await ServicesGuest.getReportGuest(params);
      const dataSubstation = await ServicesSub.detailSubstation(
        params.substation
      );
      setList(dataGuest.data.guest);
      setDataSubstation(dataSubstation.data);
      setLoading(false);

      const css = "body {background-color: white;}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      head.appendChild(style);

      printDocument();
    } catch (error) {
      setLoading(false);
      notification.error({ message: error.response.message });
    }
  }

  useEffect(() => {
    const params = {
      limit: 500,
      page: 1,
      start_date: startDate,
      end_date: endDate,
      substation,
    };
    fetchReport(params);
  }, []);

  const renderData = useCallback((data) => {
    return data.map((item, index) => {
      return (
        <TableRow key={index} height="35px">
          <TableCol leftMiddle width="5%">
            {index + 1}
          </TableCol>
          <TableCol leftMiddle width="20%">
            {item.name}
          </TableCol>
          <TableCol leftMiddle width="15%">
            {item.institution}
          </TableCol>
          <TableCol leftMiddle width="20%">
            {item.meet}
          </TableCol>
          <TableCol leftMiddle width="20%">
            {item.description}
          </TableCol>
          <TableCol middle textAlign="center" width="5%">
            {item.clock_in &&
              new Date(item.clock_in).toLocaleTimeString("in-IN", optionsTime)}
          </TableCol>
          <TableCol middle textAlign="center" width="5%">
            {item.clock_out
              ? new Date(item.clock_out).toLocaleTimeString(
                  "in-IN",
                  optionsTime
                )
              : "-"}
          </TableCol>
          <TableCol leftMiddle width="10%" />
        </TableRow>
      );
    });
  }, []);

  if (isLoading) {
    return (
      <Row className="m-5">
        <Col lg={24} className="text-center">
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  return (
    <ContainerReportInspection>
      <WrapperReport>
        <WrapperHeaderReport>
          <TableRow height="80px">
            <TableCol leftMiddle width="35%">
              <Logo src={LogoPLN} />
              PT PLN (PERSERO) UIP3B SUMATERA
            </TableCol>
            <TableCol textAlign="center" middle width="30%">
              FORMULIR BUKU PENCATATAN TAMU
            </TableCol>
            <TableCol middle width="35%">
              <Flex alignItems="center">
                <Logo src={LogoSMP} />
                SISTEM MANAJEMEN PENGAMANAN
              </Flex>
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol width="15%">No. Dokumen</TableCol>
            <TableCol width="20%">FM-SMP-UIP3BS-UPT-UP2B-031</TableCol>
            <TableCol width="30%">Tanggal</TableCol>
            <TableCol width="35%">
              {moment().format("DD MMMM YYYY").toUpperCase()}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol width="15%">Revisi</TableCol>
            <TableCol width="20%"></TableCol>
            <TableCol width="30%">Halaman</TableCol>
            <TableCol width="35%"></TableCol>
          </TableRow>
        </WrapperHeaderReport>
        <WrapperLeadReport>
          <TableRow>
            <TableCol noBorder width="10%">
              Hari/Tanggal
            </TableCol>
            <TableCol noBorderLeft noBorder>
              : {moment(startDate).format("dddd, DD MMMM YYYY")}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderTop noBorder width="10%">
              Lokasi / Area
            </TableCol>
            <TableCol noBorderTop noBorderLeft noBorder>
              : {dataSubstation?.name}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderTop noBorder width="10%">
              Periode
            </TableCol>
            <TableCol noBorderTop noBorderLeft noBorder>
              : {convertPeriode(startDate)}
            </TableCol>
          </TableRow>
        </WrapperLeadReport>
        <WrapperBodyReport>
          <TableRow style={{ fontWeight: "bold" }}>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="5%">
              NO.
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="20%">
              NAMA
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="15%">
              INSTANSI/PERUSAHAAN
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="20%">
              TUJUAN
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="20%">
              KEPERLUAN
            </TableCol>
            <TableCol textAlign="center" width="10%">
              JAM
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="10%">
              PARAF
            </TableCol>
          </TableRow>
          <TableRow style={{ fontWeight: "bold" }}>
            <TableCol noBorderTop width="5%" />
            <TableCol noBorderTop width="20%" />
            <TableCol noBorderTop width="15%" />
            <TableCol noBorderTop width="20%" />
            <TableCol noBorderTop width="20%" />
            <TableCol textAlign="center" width="5%">
              MASUK
            </TableCol>
            <TableCol textAlign="center" width="5%">
              KELUAR
            </TableCol>
            <TableCol noBorderTop width="10%" />
          </TableRow>
        </WrapperBodyReport>
        <WrapperBodyReport>{renderData(list)}</WrapperBodyReport>
      </WrapperReport>
    </ContainerReportInspection>
  );
};

export default PrintGuest;
