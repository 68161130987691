import fetch from "helper/FetchInterceptor";

const services = {};

services.getSubstation = function (params) {
  return fetch({
    url: "/substation",
    method: "get",
    params,
  });
};

services.detailSubstation = function (id) {
  return fetch({
    url: `/substation/${id}`,
    method: "get",
  });
};

services.addSubstation = function (data) {
  return fetch({
    url: "/substation",
    method: "post",
    data,
  });
};

services.updateSubstation = function (id, data) {
  return fetch({
    url: `/substation/${id}`,
    method: "put",
    data,
  });
};

services.deleteSubstation = function (id) {
  return fetch({
    url: `/substation/${id}`,
    method: "delete",
  });
};

export default services;
