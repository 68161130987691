import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Guest from "./Guest";
import User from "./User";
import Substation from "./Substation";
import Attendance from "./Attendance";
import Inspection from "./Inspection";
import PointInspection from "./PointInspection";
import Stats from "./Stats";

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Guest(),
    User(),
    Substation(),
    Attendance(),
    Inspection(),
    PointInspection(),
    Stats(),
  ]);
}
