import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  fetchInspectionSuccess,
  fetchInspectionError,
} from "../actions/Inspection";

import { inspectionService } from "services";
import { FETCH_INSPECTION } from "redux/constants/Inspection";

export function* getInspectionList() {
  yield takeEvery(FETCH_INSPECTION, function* ({ params }) {
    try {
      const data = yield call(inspectionService.getInspection, params);
      if (data.status === "success") {
        const pagination = {
          page: params.page,
          limit: params.limit,
          total: data.data.total,
        };
        yield put(fetchInspectionSuccess(data.data.inspection, pagination));
      }
    } catch (err) {
      if (err.response.message) {
        yield put(fetchInspectionError(err.response.message));
      } else {
        yield put(fetchInspectionError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getInspectionList)]);
}
