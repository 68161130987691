import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/attendance`}
          component={lazy(() => import(`./attendance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/guest`}
          component={lazy(() => import(`./guest`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/report`}
          component={lazy(() => import(`./report`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/inspection`}
          component={lazy(() => import(`./inspection`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/point-inspection`}
          component={lazy(() => import(`./point-inspection`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/substation`}
          component={lazy(() => import(`./substation`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./settings`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
