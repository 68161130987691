import fetch from "helper/FetchInterceptor";

const services = {};

services.getInspection = function (params) {
  return fetch({
    url: "/inspection",
    method: "get",
    params,
  });
};
services.updateStatus = function (id, data) {
  return fetch({
    url: `/inspection/status/${id}`,
    method: "put",
    data,
  });
};

export default services;
