import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  RESET_USER,
} from "redux/constants/User";

export const fetchUser = (params) => {
  return {
    type: FETCH_USER,
    params,
  };
};

export const fetchUserSuccess = (data, pagination) => {
  return {
    type: FETCH_USER_SUCCESS,
    data,
    pagination,
  };
};

export const fetchUserError = (error) => {
  return {
    type: FETCH_USER_ERROR,
    error,
  };
};

export const registerUser = (data) => {
  return {
    type: REGISTER_USER,
    data,
  };
};

export const registerUserSuccess = () => {
  return {
    type: REGISTER_USER_SUCCESS,
  };
};

export const registerUserError = (error) => {
  return {
    type: REGISTER_USER_ERROR,
    error,
  };
};

export const resetUser = () => {
  return {
    type: RESET_USER,
  };
};
