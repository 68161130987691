import Flex from "components/shared-components/Flex";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import LogoPLN from "assets/image/logo-pln.png";
import LogoSMP from "assets/image/logo-smp.png";
import moment from "moment";
import { useLocation } from "react-router-dom";
import "moment/locale/id";
import services from "services/report";
import { Col, notification, Row, Spin } from "antd";

moment.locale("id");

const BLUE_BACKGROUND = "#D2EBFD";

const ContainerReportInspection = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  padding: 20px 15px;
`;

const WrapperReport = styled.div`
  background-color: white;
  border: solid 1px black;
  width: 100%;
`;

const WrapperHeaderReport = styled.div`
  background-color: ${BLUE_BACKGROUND};
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  color: black;
`;

const WrapperLeadReport = styled.div`
  background-color: white;
  width: 100%;
  font-size: 11px;
  color: black;
  letter-spacing: 0.3px;
`;

const WrapperBodyReport = styled.div`
  background-color: white;
  width: 100%;
  font-size: 11px;
  color: black;
  letter-spacing: 0.3px;
`;

const WrapperFooterReport = styled.div`
  background-color: white;
  width: 100%;
  font-size: 11px;
  color: black;
  letter-spacing: 0.3px;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: ${({ height }) => (height ? height : "26px")};
  width: 100%;
  border-right: solid 1px black;
`;

const TableCol = styled.td`
  border: ${({ noBorder }) => (noBorder ? 0 : "solid 1px black")};
  border-top: ${({ noBorderTop }) => (noBorderTop ? 0 : "solid 1px black")};
  border-bottom: 0;
  border-right: 0;
  border-left: ${({ noBorderLeft }) => (noBorderLeft ? 0 : "solid 1px black")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  width: ${({ width }) => (width ? width : null)};
  flex: ${({ width }) => (width ? null : 1)};
  padding: 5px;
  ${({ leftMiddle }) =>
    leftMiddle &&
    `display: flex; justify-content: flex-start; align-items: center;`};
  ${({ middle }) =>
    middle && `display: flex; justify-content: center; align-items: center;`};
`;

const Logo = styled.img`
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: contain;
`;

const PrintInspection = () => {
  const [isLoading, setLoading] = useState(true);
  const [dataInspection, setDataInspection] = useState([]);
  const [dataUser, setDataUser] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const date = queryParams.get("date");
  const security = queryParams.get("security");

  const printDocument = () => window.print();

  async function fetchReport() {
    try {
      const params = {
        date,
        security,
      };
      setLoading(true);
      const response = await services.getReportInspection(params);
      setDataUser(response.data.user);
      setDataInspection(response.data.inspection);
      setLoading(false);

      const css = "body {background-color: white;}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      head.appendChild(style);

      printDocument();
    } catch (error) {
      setLoading(false);
      notification.error({ message: error.response.message });
    }
  }

  useEffect(() => {
    fetchReport();
  }, []);

  const renderInspectionList = useMemo(() => {
    return dataInspection.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCol textAlign="center" noBorderLeft width="10%">
            {index + 1}
          </TableCol>
          <TableCol textAlign="center" width="15%">
            {moment(item?.createdAt).format("DD/MM/YYYY HH:MM")}
          </TableCol>
          <TableCol width="30%">{item?.PointInspection?.name}</TableCol>
          <TableCol textAlign="center" width="10%">
            {item?.condition === "Aman" && "✅"}
          </TableCol>
          <TableCol textAlign="center" width="10%">
            {item?.condition === "Tidak Aman" && "❌"}
          </TableCol>
          <TableCol width="30%">{item?.description}</TableCol>
        </TableRow>
      );
    });
  }, [dataInspection]);

  if (isLoading) {
    return (
      <Row className="m-5">
        <Col lg={24} className="text-center">
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  return (
    <ContainerReportInspection>
      <WrapperReport>
        <WrapperHeaderReport>
          <TableRow height="80px">
            <TableCol noBorderLeft leftMiddle width="35%">
              <Logo src={LogoPLN} />
              PT PLN (PERSERO)
              <br />
              UIP3B SUMATERA
            </TableCol>
            <TableCol textAlign="center" middle width="30%">
              FORMULIR CHECKLIST INSPEKSI TEMPAT KERJA
            </TableCol>
            <TableCol middle width="35%">
              <Flex alignItems="center">
                <Logo src={LogoSMP} />
                SISTEM MANAJEMEN PENGAMANAN
              </Flex>
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderLeft width="15%">
              No. Dokumen
            </TableCol>
            <TableCol width="20%">FM-SMP-UIP3BS-UPT-UP2B-019</TableCol>
            <TableCol width="30%">Tanggal</TableCol>
            <TableCol width="35%">
              {moment().format("DD MMMM YYYY").toUpperCase()}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderLeft width="15%">
              Revisi
            </TableCol>
            <TableCol width="20%"></TableCol>
            <TableCol width="30%">Halaman</TableCol>
            <TableCol width="35%"></TableCol>
          </TableRow>
        </WrapperHeaderReport>
        <WrapperLeadReport>
          <TableRow>
            <TableCol noBorderLeft textAlign="center">
              CHECKLIST INSPEKSI TEMPAT KERJA
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderTop noBorderLeft noBorder width="10%">
              Hari/Tanggal
            </TableCol>
            <TableCol noBorderTop noBorderLeft noBorder>
              : {moment(date).format("dddd, DD MMMM YYYY")}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderTop noBorderLeft noBorder width="10%">
              Tempat
            </TableCol>
            <TableCol noBorderTop noBorderLeft noBorder>
              : {dataUser?.Substation?.name}
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderTop noBorderLeft noBorder width="10%">
              Nama Satpam
            </TableCol>
            <TableCol noBorderTop noBorderLeft noBorder>
              : {dataUser?.fullname}
            </TableCol>
          </TableRow>
        </WrapperLeadReport>
        <WrapperBodyReport>
          <TableRow style={{ fontWeight: "bold" }}>
            <TableCol
              style={{ paddingTop: 15 }}
              textAlign="center"
              noBorderLeft
              width="10%"
            >
              No.
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="15%">
              Jam
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="30%">
              Uraian Kegiatan
            </TableCol>
            <TableCol textAlign="center" width="20%">
              Hasil Checklist
            </TableCol>
            <TableCol style={{ paddingTop: 15 }} textAlign="center" width="30%">
              Keterangan (Kejadian yang dilihat)
            </TableCol>
          </TableRow>
          <TableRow style={{ fontWeight: "bold" }}>
            <TableCol noBorderTop noBorderLeft width="10%"></TableCol>
            <TableCol noBorderTop width="15%"></TableCol>
            <TableCol noBorderTop width="30%"></TableCol>
            <TableCol textAlign="center" width="10%">
              Aman (√)
            </TableCol>
            <TableCol textAlign="center" width="10%">
              Tidak Aman (X)
            </TableCol>
            <TableCol noBorderTop width="30%"></TableCol>
          </TableRow>
        </WrapperBodyReport>
        <WrapperBodyReport>{renderInspectionList}</WrapperBodyReport>
        <WrapperFooterReport>
          <TableRow>
            <TableCol noBorderLeft textAlign="center" width="50%"></TableCol>
            <TableCol noBorderLeft textAlign="center" width="50%"></TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderTop noBorderLeft textAlign="center" width="50%">
              Mengetahui :
            </TableCol>
            <TableCol noBorderTop noBorderLeft textAlign="center" width="50%">
              Dibuat Oleh :
            </TableCol>
          </TableRow>
          <TableRow height="50px">
            <TableCol
              noBorderTop
              noBorderLeft
              textAlign="center"
              width="50%"
            ></TableCol>
            <TableCol
              noBorderLeft
              noBorderTop
              textAlign="center"
              width="50%"
            ></TableCol>
          </TableRow>
          <TableRow>
            <TableCol noBorderTop noBorderLeft textAlign="center" width="50%">
              {(dataUser?.Substation?.danru)?.toUpperCase()}
            </TableCol>
            <TableCol noBorderLeft noBorderTop textAlign="center" width="50%">
              {(dataUser?.fullname)?.toUpperCase()}
            </TableCol>
          </TableRow>
        </WrapperFooterReport>
      </WrapperReport>
    </ContainerReportInspection>
  );
};

export default PrintInspection;
