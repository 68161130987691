import {
  FETCH_STATS,
  FETCH_STATS_ERROR,
  FETCH_STATS_SUCCESS,
} from "redux/constants/Stats";

export const fetchStats = () => {
  return {
    type: FETCH_STATS,
  };
};

export const fetchStatsSuccess = (data, overview) => {
  return {
    type: FETCH_STATS_SUCCESS,
    data,
    overview,
  };
};

export const fetchStatsError = (error) => {
  return {
    type: FETCH_STATS_ERROR,
    error,
  };
};
