import { ADD_PROFILE, PROFILE } from "redux/constants/Profile";

const initState = JSON.parse(localStorage.getItem(PROFILE));

const profile = (state = initState, action) => {
  switch (action.type) {
    case ADD_PROFILE:
      return action.profile;
    default:
      return state;
  }
};

export default profile;
