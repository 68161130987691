import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN, REFRESH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import { PROFILE } from "redux/constants/Profile";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken) {
      //   history.push(ENTRY_ROUTE);
      //   window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    let notificationParam = {
      message: "Gagal Mendapatkan Data, Coba Lagi",
    };

    // Remove token and redirect
    if (error.response.status === 401) {
      const profile = localStorage.getItem(PROFILE);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      try {
        const email = JSON.parse(profile)?.email;
        const data = await service.post("/refresh-token", {
          email,
          refresh_token: refreshToken,
        });
        localStorage.setItem(AUTH_TOKEN, data.data.token);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }

    if (error.response.status === 404) {
      notificationParam.message = "Not Found";
    }
  
    if (error.response.status === 405) {
      notificationParam.message = "Not Allowed";
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
