import fetch from "helper/FetchInterceptor";

const services = {};

services.getReportGuest = function (params) {
  return fetch({
    url: "/report/guest",
    method: "get",
    params,
  });
};

services.getCounterGuest = function (params) {
  return fetch({
    url: "/report/count-guest",
    method: "get",
    params,
  });
};

services.getCounterInspection = function (params) {
  return fetch({
    url: "/report/count-inspection",
    method: "get",
    params,
  });
};

services.getReportInspection = function (params) {
  return fetch({
    url: "/report/inspection",
    method: "get",
    params,
  });
};

export default services;
