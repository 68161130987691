import {
  FETCH_GUEST,
  FETCH_GUEST_ERROR,
  FETCH_GUEST_SUCCESS,
} from "redux/constants/Guest";

export const fetchGuest = (params) => {
  return {
    type: FETCH_GUEST,
    params,
  };
};

export const fetchGuestSuccess = (data, pagination) => {
  return {
    type: FETCH_GUEST_SUCCESS,
    data,
    pagination,
  };
};

export const fetchGuestError = (error) => {
  return {
    type: FETCH_GUEST_ERROR,
    error,
  };
};
