import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  fetchPointInspectionSuccess,
  fetchPointInspectionError,
} from "../actions/PointInspection";

import { pointInspectionService } from "services";
import { FETCH_POINT_INSPECTION } from "redux/constants/PointInspection";

export function* getPointInspectionList() {
  yield takeEvery(FETCH_POINT_INSPECTION, function* ({ params }) {
    try {
      const data = yield call(
        pointInspectionService.getPointInspection,
        params
      );
      if (data.status === "success") {
        const pagination = {
          page: params.page,
          limit: params.limit,
          total: data.data.total,
        };
        yield put(
          fetchPointInspectionSuccess(data.data.pointInspection, pagination)
        );
      }
    } catch (err) {
      if (err.response.message) {
        yield put(fetchPointInspectionError(err.response.message));
      } else {
        yield put(fetchPointInspectionError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getPointInspectionList)]);
}
