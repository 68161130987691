import fetch from "helper/FetchInterceptor";

const services = {};

services.getUsers = function (params) {
  return fetch({
    url: "/user",
    method: "get",
    params,
  });
};

services.addUsers = function (data) {
  return fetch({
    url: "/auth/register",
    method: "post",
    data,
  });
};

services.updateUsers = function (data) {
  return fetch({
    url: `/user/${data.id}`,
    method: "put",
    data,
  });
};

services.deleteUser = function (id) {
  return fetch({
    url: `/user/${id}`,
    method: "delete",
  });
};

export default services;
