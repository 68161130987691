import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import { attendanceService } from "services";
import { FETCH_ATTENDANCE } from "redux/constants/Attendance";
import { fetchAttendanceSuccess, fetchAttendanceError } from "redux/actions";

export function* getAttendanceList() {
  yield takeEvery(FETCH_ATTENDANCE, function* ({ params }) {
    try {
      const data = yield call(attendanceService.getAttendance, params);
      if (data.status === "success") {
        const pagination = {
          page: params.page,
          limit: params.limit,
          total: data.data.total,
        };
        yield put(fetchAttendanceSuccess(data.data.attendances, pagination));
      }
    } catch (err) {
      if (err.response.message) {
        yield put(fetchAttendanceError(err.response.message));
      } else {
        yield put(fetchAttendanceError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getAttendanceList)]);
}
