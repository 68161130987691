import {
  FETCH_GUEST,
  FETCH_GUEST_SUCCESS,
  FETCH_GUEST_ERROR,
} from "../constants/Guest";

const initState = {
  pagination: {
    page: 1,
    limit: 10,
    total: 0,
  },
  loading: true,
  data: [],
  error: null,
};

const guest = (state = initState, action) => {
  switch (action.type) {
    case FETCH_GUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_GUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        pagination: action.pagination,
        data: action.data,
      };
    case FETCH_GUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default guest;
