import {
  FETCH_POINT_INSPECTION,
  FETCH_POINT_INSPECTION_ERROR,
  FETCH_POINT_INSPECTION_SUCCESS,
} from "redux/constants/PointInspection";

export const fetchPointInspection = (params) => {
  return {
    type: FETCH_POINT_INSPECTION,
    params,
  };
};

export const fetchPointInspectionSuccess = (data, pagination) => {
  return {
    type: FETCH_POINT_INSPECTION_SUCCESS,
    data,
    pagination,
  };
};

export const fetchPointInspectionError = (error) => {
  return {
    type: FETCH_POINT_INSPECTION_ERROR,
    error,
  };
};
