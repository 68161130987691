import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { fetchStatsSuccess, fetchStatsError } from "../actions/Stats";

import { statsService } from "services";
import { FETCH_STATS } from "redux/constants/Stats";

export function* getStats() {
  yield takeEvery(FETCH_STATS, function* () {
    try {
      const data = yield call(statsService.getStats);
      if (data.status === "success") {
        const overview = [
          {
            title: "TITIK INSPEKSI",
            value: data.data.overview.total_point_inspection,
            subtitle: `Titik`,
          },
          {
            title: "GARDU INDUK",
            value: data.data.overview.total_substation,
            subtitle: `Titik`,
          },
          {
            title: "TAMU",
            value: data.data.overview.total_guest,
            subtitle: `Orang`,
          },
          {
            title: "PENGGUNA (Security)",
            value: data.data.overview.total_security,
            subtitle: `Orang`,
          },
        ];
        console.log(overview)
        yield put(fetchStatsSuccess(data.data, overview));
      }
    } catch (err) {
      if (err.response.message) {
        yield put(fetchStatsError(err.response.message));
      } else {
        yield put(fetchStatsError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getStats)]);
}
