import fetch from "helper/FetchInterceptor";

const services = {};

services.getAttendance = function (params) {
  return fetch({
    url: "/attendance",
    method: "get",
    params,
  });
};

export default services;
