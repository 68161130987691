import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  p,
  ul,
  li,
  h4,
  h1 {
    color: black;
  }
`;

function PrivacyPolicies(): JSX.Element {
  return (
    <Container>
      <h1>Kebijakan Privasi</h1>
      <p>
        Kebijakan Privasi berikut menjelaskan bagaimana kami,{" "}
        <strong>ASEC PLN </strong>
        mengumpulkan, menyimpan, menggunakan, memproses, menyimpan, mentransfer,
        mengungkapkan, dan melindungi Informasi Pribadi Anda. Kebijakan Privasi
        ini berlaku untuk semua pengguna aplikasi kami, layanan, atau produk
        (“Aplikasi”), kecuali dicakup oleh kebijakan privasi terpisah.
      </p>
      <p>
        Harap baca Kebijakan Privasi ini secara menyeluruh untuk memastikan
        bahwa Anda memahami praktik pemrosesan data kami. Kecuali ditentukan
        lain, semua istilah yang digunakan dalam Kebijakan Privasi ini akan
        memiliki arti yang sama dengan yang tercantum dalam Ketentuan
        Penggunaan.
      </p>
      <p>Kebijakan Privasi ini mencakup hal-hal berikut:</p>
      <ul>
        <li>Informasi Pribadi yang kami kumpulkan.</li>
        <li>Penggunaan Informasi Pribadi yang kami kumpulkan.</li>
        <li>Penyimpanan Informasi Pribadi.</li>
        <li>Di mana kami menyimpan Informasi Pribadi Anda.</li>
        <li>Keamanan Informasi Pribadi Anda.</li>
        <li>Perubahan pada Kebijakan Privasi ini.</li>
        <li>Pengakuan dan persetujuan.</li>
        <li>Materi pemasaran dan promosi.</li>
        <li>Platform pihak ketiga.</li>
        <li>Bagaimana cara menghubungi kami.</li>
      </ul>
      <h4>1. INFORMASI PRIBADI YANG KAMI KUMPULKAN</h4>
      <p>
        Kami mengumpulkan informasi yang mengidentifikasi atau dapat digunakan
        untuk mengidentifikasi, menghubungi, yang terkait dengan informasi
        tersebut (“Informasi Pribadi”). Informasi Pribadi termasuk adalah akunt
        nomor Whatsapp Anda dan Nama Anda . Kami dapat mengumpulkan informasi
        untuk menindaklanjuti proses pemasaran pada aplikasi kami (termasuk
        tujuan yang diizinkan oleh hukum yang berlaku).
      </p>
      <p>
        Ketika Anda melakukan proses order dengan kami menggunakan Aplikasi,
        Anda harus memberikan kepada kami Informasi Pribadi tertentu, termasuk
        nama, alamat fisik, nomor telepon Anda, yang gunakan untuk mengakses
        order via Aplikasi dan mempermudah proses transaksi jual beli.
      </p>
      <p>
        Informasi dikumpulkan setiap kali Anda menggunakan Aplikasi atau
        mengunjungi situs web kami
      </p>
      <p>
        Setiap kali Anda menggunakan Aplikasi atau mengunjungi situs web kami,
        kami dapat mengumpulkan data teknis tertentu tentang penggunaan Anda
        seperti, alamat protokol internet (IP), durasi setiap kunjungan / sesi,
        identitas perangkat internet ( ID) atau alamat kontrol akses media, dan
        juga informasi mengenai pabrik, model dan sistem operasi perangkat yang
        Anda gunakan untuk mengakses Aplikasi atau situs web kami.
        <br />
        Setiap kali Anda menggunakan Aplikasi atau mengunjungi situs web kami,
        informasi tertentu juga dapat dikumpulkan secara otomatis menggunakan
        cookie. Cookie adalah file aplikasi kecil yang disimpan di komputer atau
        perangkat seluler Anda. Kami menggunakan cookie untuk melacak aktivitas
        pengguna untuk meningkatkan antarmuka dan pengalaman pengguna. Sebagian
        besar perangkat seluler dan browser internet mendukung penggunaan
        cookie; tetapi Anda dapat menyesuaikan pengaturan pada perangkat seluler
        atau browser internet Anda untuk menolak beberapa jenis cookie tertentu
        atau cookie tertentu. Perangkat seluler dan / atau browser Anda juga
        akan memungkinkan Anda untuk menghapus kapan saja cookie apa pun
        sebelumnya telah disimpan. Namun, hal itu dapat memengaruhi
        fungsionalitas yang tersedia di Aplikasi atau situs web kami.
      </p>
      <h4>2. PENGGUNAAN INFORMASI PRIBADI YANG KAMI KUMPULKAN</h4>
      <p>
        Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan
        berikut maupun untuk tujuan lain sebagaimana diizinkan oleh hukum yang
        berlaku (“Tujuan”):
      </p>
      <p>
        Di mana Anda adalah pengguna, kami dapat menggunakan Informasi Pribadi
        Anda:
      </p>
      <p>
        untuk mengidentifikasi Anda dan mendaftarkan Anda sebagai pengguna dan
        untuk mengelola proses monitoring keamanan.
        <br />
        untuk memungkinkan penyedia layanan memberi Anda layanan seperti yang
        Anda minta;
        <br />
        untuk memproses dan memfasilitasi pesanan dan transaksi pembayaran yang
        dilakukan oleh Anda;
        <br />
        untuk berkomunikasi dengan Anda dan mengirimi Anda informasi sehubungan
        dengan penggunaan Aplikasi;
        <br />
        untuk memberi tahu Anda tentang segala pembaruan pada Aplikasi atau
        perubahan pada layanan yang tersedia;
        <br />
        untuk memproses dan menanggapi pertanyaan dan umpan balik yang diterima
        dari Anda;
        <br />
        untuk memelihara, mengembangkan, menguji, meningkatkan, dan
        mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan preferensi
        Anda sebagai pengguna;
        <br />
        untuk memantau dan menganalisis aktivitas pengguna, perilaku, dan data
        demografis termasuk tren dan penggunaan berbagai layanan yang tersedia
        di Aplikasi;
        <br />
        untuk memproses dan mengelola poin imbalan Anda;
        <br />
        untuk menawarkan atau menyediakan layanan dari afiliasi atau mitra kami;
        dan
        <br />
        untuk mengirimi Anda komunikasi komunikasi, iklan, voucher, survei, dan
        informasi pemasaran langsung atau bertarget, atau informasi tentang
        penawaran atau promosi khusus.
      </p>
      <h4>3. BERBAGI INFORMASI PRIBADI YANG KAMI KUMPULKAN</h4>
      <p>
        Kami dapat mengungkapkan kepada atau membagikan Informasi Pribadi Anda
        dengan pihak afiliasi dan pihak lain untuk tujuan apa pun berikut ini
        serta untuk tujuan lain yang diizinkan oleh hukum yang berlaku:
      </p>
      <p>
        di mana Anda adalah pengguna, untuk tujuan memungkinkan penyedia
        layanan, untuk melakukan atau memberikan layanan;
        <br />
        di mana disyaratkan atau diotorisasi oleh hukum yang berlaku (termasuk
        tetapi tidak terbatas pada menanggapi permintaan, penyelidikan, atau
        arahan peraturan, atau mematuhi persyaratan pengarsipan dan pelaporan
        menurut undang-undang atau peraturan), untuk tujuan yang ditentukan
        dalam Hukum yang Berlaku;
        <br />
        di mana ada segala bentuk proses hukum antara Anda dan kami, atau antara
        Anda dan pihak lain, sehubungan dengan, atau terkait dengan layanan,
        untuk keperluan proses hukum tersebut;
        <br />
        Apabila Informasi Pribadi tidak perlu dikaitkan dengan Anda, kami akan
        melakukan upaya yang wajar untuk menghapus sarana yang dengannya
        Informasi Pribadi dapat dikaitkan dengan Anda sebagai individu sebelum
        mengungkapkan atau berbagi informasi tersebut.
        <br />
        Kami tidak akan menjual atau menyewakan Informasi Pribadi Anda.
        <br />
        Selain sebagaimana diatur dalam Kebijakan Privasi ini, kami dapat
        mengungkapkan atau membagikan Informasi Pribadi Anda jika kami memberi
        tahu Anda dan kami telah memperoleh persetujuan Anda untuk pengungkapan
        atau berbagi.
      </p>
      <h4>4. RETENSI INFORMASI PRIBADI</h4>
      <p>
        Informasi Pribadi Anda hanya akan disimpan selama diperlukan untuk
        memenuhi tujuan pengumpulannya, atau selama penyimpanan tersebut
        diperlukan atau disahkan oleh Hukum yang Berlaku. Kami akan berhenti
        menyimpan Informasi Pribadi, atau menghapus sarana yang dengannya
        Informasi Pribadi dapat dikaitkan dengan Anda sebagai individu, segera
        setelah masuk akal untuk mengasumsikan bahwa tujuan pengumpulan
        Informasi Pribadi itu tidak lagi dilayani oleh penyimpanan Informasi
        Pribadi dan penyimpanan tidak lagi diperlukan untuk tujuan hukum atau
        bisnis.
      </p>
      <h4>5. AKSES DAN KOREKSI INFORMASI PRIBADI</h4>
      <p>
        Tunduk pada hukum yang berlaku, Anda dapat meminta kami untuk mengakses
        dan / atau mengoreksi Informasi Pribadi Anda dalam kepemilikan dan
        kontrol kami, dengan menghubungi kami di perincian yang disediakan di
        bawah ini
      </p>
      <h4>6. DI MANA KAMI MENYIMPAN INFORMASI PRIBADI ANDA</h4>
      <p>
        Informasi Pribadi yang kami kumpulkan dari Anda dapat disimpan di,
        ditransfer ke, atau diproses di Whatsapp sebagai pihak ketiga . Kami
        akan menggunakan semua upaya yang wajar untuk memastikan bahwa semua
        penyedia layanan pihak ketiga tersebut memberikan tingkat perlindungan
        yang sebanding dengan komitmen kami berdasarkan Kebijakan Privasi ini.
      </p>
      <h4>7. KEAMANAN INFORMASI PRIBADI ANDA</h4>
      <p>
        Kerahasiaan Informasi Pribadi Anda sangat penting bagi kami. Kami akan
        menggunakan semua upaya yang wajar untuk melindungi dan mengamankan
        Informasi Pribadi Anda dari akses, pengumpulan, penggunaan atau
        pengungkapan oleh orang yang tidak berwenang dan terhadap pemrosesan
        yang melanggar hukum, kehilangan, kerusakan dan kerusakan yang tidak
        disengaja atau risiko serupa. Sayangnya, pengiriman informasi melalui
        Internet tidak sepenuhnya aman. Meskipun kami akan melakukan yang
        terbaik untuk melindungi Informasi Pribadi Anda, Anda mengakui bahwa
        kami tidak dapat menjamin integritas dan keakuratan Informasi Pribadi
        yang Anda kirimkan melalui Internet.
      </p>
      <h4>8. PERUBAHAN KEBIJAKAN PRIVASI INI</h4>
      <p>
        Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas kebijakan
        kami sendiri dari waktu ke waktu, untuk memastikan bahwa itu konsisten
        dengan perkembangan kami di masa depan, dan / atau perubahan dalam
        persyaratan hukum atau peraturan. Jika kami memutuskan untuk mengubah
        Kebijakan Privasi ini, kami akan memberi tahu Anda tentang perubahan
        tersebut melalui pemberitahuan umum yang dipublikasikan pada Aplikasi
        dan / atau situs web. Anda setuju bahwa adalah tanggung jawab Anda untuk
        meninjau Kebijakan Privasi ini secara teratur untuk informasi terbaru
        tentang pemrosesan data dan praktik perlindungan data kami, dan bahwa
        Anda terus menggunakan Aplikasi atau situs web, komunikasi dengan kami,
        atau akses ke dan penggunaan layanan berikut setiap perubahan pada
        Kebijakan Privasi ini akan merupakan penerimaan Anda terhadap Kebijakan
        Privasi ini dan semua amandemennya.
      </p>
      <h4>9. UCAPAN TERIMA KASIH DAN PERSETUJUAN</h4>
      <p>
        Dengan menerima Kebijakan Privasi, Anda mengakui bahwa Anda telah
        membaca dan memahami Kebijakan Privasi ini dan Anda menerima semua
        ketentuannya. Secara khusus, Anda setuju dan menyetujui kami
        mengumpulkan, menggunakan, berbagi, mengungkapkan, menyimpan,
        mentransfer, atau memproses Informasi Pribadi Anda sesuai dengan
        Kebijakan Privasi ini.
        <br />
        Dalam keadaan di mana Anda memberikan kepada kami Informasi Pribadi yang
        berkaitan dengan orang lain (seperti Informasi Pribadi yang berkaitan
        dengan pasangan Anda, anggota keluarga, teman, atau pihak lain), Anda
        menyatakan dan menjamin bahwa Anda telah memperoleh persetujuan individu
        tersebut untuk, dan dengan ini menyetujui atas nama individu tersebut
        untuk, pengumpulan, penggunaan, pengungkapan dan pemrosesan Informasi
        Pribadi oleh kami.
        <br />
        Anda dapat menarik persetujuan Anda untuk setiap atau semua pengumpulan,
        penggunaan atau pengungkapan Informasi Pribadi Anda kapan saja dengan
        memberi kami pemberitahuan yang masuk akal secara tertulis menggunakan
        perincian kontak yang dinyatakan di bawah ini. Anda juga dapat menarik
        persetujuan Anda agar kami mengirimi Anda komunikasi dan informasi
        tertentu.
      </p>
      <h4>10. BAGAIMANA MENGHUBUNGI KAMI</h4>
      <p>
        Jika Anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau Anda
        ingin mendapatkan akses dan / atau melakukan koreksi terhadap Informasi
        Pribadi Anda, silakan hubungi
        <a href="mailto:info@asec-uptpalembang.com">
          info@asec-uptpalembang.com
        </a>
        .
      </p>
    </Container>
  );
}

export default PrivacyPolicies;
