import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  RESET_USER,
} from "../constants/User";

const initState = {
  pagination: {
    page: 1,
    limit: 10,
    total: 0,
  },
  loading: false,
  data: [],
  error: null,
};

const guest = (state = initState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        pagination: action.pagination,
        data: action.data,
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RESET_USER:
      return {
        ...state,
        loading: false,
        error: null,
        data: [],
      };

    default:
      return state;
  }
};

export default guest;
