import {
  FETCH_STATS,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_ERROR,
} from "../constants/Stats";

const initState = {
  loading: true,
  data: {},
  error: null,
  overview: [
    {
      title: "TITIK INSPEKSI",
      value: 0,
      subtitle: `Titik`,
    },
    {
      title: "GARDU INDUK",
      value: 0,
      subtitle: `Titik`,
    },
    {
      title: "TAMU",
      value: 0,
      subtitle: `Orang`,
    },
    {
      title: "PENGGUNA (Security)",
      value: 0,
      subtitle: `Orang`,
    },
  ],
};

const stats = (state = initState, action) => {
  switch (action.type) {
    case FETCH_STATS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        overview: action.overview,
      };
    case FETCH_STATS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default stats;
