import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { fetchGuestError, fetchGuestSuccess } from "../actions/Guest";

import { guestServices } from "services";
import { FETCH_GUEST } from "redux/constants/Guest";

export function* getGuestList() {
  yield takeEvery(FETCH_GUEST, function* ({ params }) {
    try {
      const data = yield call(guestServices.getGuest, params);
      if (data.status === "success") {
        const pagination = {
          page: params.page,
          limit: params.limit,
          total: data.data.total,
        };
        yield put(fetchGuestSuccess(data.data.guest, pagination));
      }
    } catch (err) {
      if (err.response.message) {
        yield put(fetchGuestError(err.response.message));
      } else {
        yield put(fetchGuestError("Gagal Mendapatkan Data"));
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getGuestList)]);
}
